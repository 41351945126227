/*

Intellij Idea-like styling (c) Vasily Polovnyov <vast@whiteants.net>

*/

.hljs {
  display: block;
  overflow-x: auto;
  padding: 0.5em;
  color: #000;
  background: #fff;
}

.hljs-subst,
.hljs-title {
  font-weight: normal;
  color: #000;
}

.hljs-comment,
.hljs-quote {
  color: #808080;
  font-style: italic;
}

.hljs-meta {
  color: #808000;
}

.hljs-tag {
  background: #efefef;
}

.hljs-section,
.hljs-name,
.hljs-literal,
.hljs-keyword,
.hljs-selector-tag,
.hljs-type,
.hljs-selector-id,
.hljs-selector-class {
  font-weight: bold;
  color: #000080;
}

.hljs-attribute,
.hljs-number,
.hljs-regexp,
.hljs-link {
  font-weight: bold;
  color: #0000ff;
}

.hljs-number,
.hljs-regexp,
.hljs-link {
  font-weight: normal;
}

.hljs-string {
  color: #008000;
  font-weight: bold;
}

.hljs-symbol,
.hljs-bullet,
.hljs-formula {
  color: #000;
  background: #d0eded;
  font-style: italic;
}

.hljs-doctag {
  text-decoration: underline;
}

.hljs-variable,
.hljs-template-variable {
  color: #660e7a;
}

.hljs-addition {
  background: #baeeba;
}

.hljs-deletion {
  background: #ffc8bd;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: bold;
}
